import React from "react"
import {useTranslation} from "react-i18next"
import {PlusIcon} from "@heroicons/react/20/solid"
import {InformationCircleIcon, TrashIcon} from "@heroicons/react/24/outline"

import {GenericErrorAlert} from "../../../../../components/Alert.tsx"
import Button from "../../../../../components/Button.tsx"
import {Loading} from "../../../../../components/Loading.tsx"
import {Table} from "../../../../../components/Table/Table.tsx"
import {TableRow} from "../../../../../components/Table/TableRow.tsx"
import {TColumnsMeta, TOrderBy} from "../../../../../components/Table/utils/shared.ts"
import {TabItem, TabsContainer} from "../../../../../components/Tabs.tsx"
import {useAdvancedSearchDraftedListQuery} from "../../../../../queries/advancedSearch.ts"
import {
  AAdvancedSearchDraftedProspect,
  AAdvancedSearchSessionStatuses,
} from "../../../../../services/types.generated.ts"
import {EMPTY_ARRAY} from "../../../../../utils"
import {ProspectsFromLeadsFrame} from "../../ProspectsFromLeadsFrame.tsx"
import {ProspectsFromLeadsLayout} from "../../ProspectsFromLeadsLayout.tsx"
import {AdvancedLeadsContext, SearchingStageContext} from "../context/context.ts"
import {useSearchingStage} from "../context/useSearchingStage.ts"
import {SearchingStageButton} from "../NextAdvancedSearchPageButton.tsx"
import {tableLoadingIndicator} from "../Table/common.tsx"
import {useCompanySizeColumn} from "../Table/companySizeColumn.tsx"
import {NoResults} from "../Table/NoResults.tsx"
import {useSourceColumn} from "../Table/sourceColumn.tsx"
import {useWebsiteColumn} from "../Table/websiteColumn.tsx"
import {TSearchingStageColumn, TSearchingStageRow} from "../types.ts"

export const AdvancedSearchSearchingStage: React.FC = () => {
  const {t} = useTranslation()

  const {sessionId, setStage} = AdvancedLeadsContext.useContext()

  if (sessionId == null) {
    throw new Error("sessionId is not initialized")
  }

  const [orderBy, setOrderBy] = React.useState<TOrderBy<TSearchingStageColumn>>(undefined)

  const {data, error, refetch, isLoading} = useAdvancedSearchDraftedListQuery({
    sessionId,
    orderBy,
  })
  const isLoadingData = (isLoading || data?.meta?.ongoing_search) ?? false
  const prospects = data?.drafted_prospects ?? (EMPTY_ARRAY as unknown as AAdvancedSearchDraftedProspect[])

  const searchingStageContextValue = SearchingStageContext.useProviderValue(
    useSearchingStage(prospects, orderBy, setOrderBy, isLoadingData)
  )
  const {tab, setTab, removedSearchIds, addRemovedSearchId, removeRemovedSearchId, rows} = searchingStageContextValue

  const websiteColumn = useWebsiteColumn<TSearchingStageColumn, TSearchingStageRow>()
  const companySizeColumn = useCompanySizeColumn<TSearchingStageColumn, TSearchingStageRow>()
  const sourceColumn = useSourceColumn<TSearchingStageColumn, TSearchingStageRow>()

  const columnsMeta = React.useMemo<TColumnsMeta<TSearchingStageColumn, TSearchingStageRow>>(
    () => [
      sourceColumn,
      {
        column: "name",
        sortFn: true,
        headerCellClassName: "!bg-cr-blue-super-light",
        HeaderCellValue: () => t("Leads_Table_Company"),
        CellValue: ({row}) => (row.id === "loading" ? tableLoadingIndicator : row.organization_name),
      },
      websiteColumn,
      companySizeColumn,
    ],
    [companySizeColumn, t, websiteColumn, sourceColumn]
  )

  React.useEffect(() => {
    if (!data || !data.meta || !data.drafted_prospects) {
      return
    }

    if (data.meta.search_status === AAdvancedSearchSessionStatuses.Failed && data.drafted_prospects.length === 0) {
      setStage("error")
    }
  }, [data, setStage])

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  const addedNum = prospects.length - removedSearchIds.length
  const removedNum = removedSearchIds.length

  return (
    <SearchingStageContext value={searchingStageContextValue}>
      <ProspectsFromLeadsLayout nextStepButton={<SearchingStageButton />}>
        <ProspectsFromLeadsFrame className={"p-0"}>
          <div className={"flex h-full w-full flex-col"}>
            <div className={"flex justify-between gap-10 border-b border-cr-blue-light"}>
              <TabsContainer size={"sm"} className={"min-h-[36px] w-auto border-b-0 px-4"}>
                <TabItem isActive={tab === "added"} onClick={() => setTab("added")}>
                  <div className={"relative flex items-baseline gap-1 text-cr-black"}>
                    <span className={"text-base font-semibold"}>{t("Leads_Table_Total")} </span>
                    <span className={"text-sm"}>({addedNum})</span>
                    {isLoadingData && (
                      <Loading size={"xs"} fullSpace={false} containerClassName={"absolute top-1.5 -right-6"} />
                    )}
                  </div>
                </TabItem>
                <TabItem isActive={tab === "removed"} onClick={() => setTab("removed")}>
                  <div className={"flex items-baseline gap-1 text-cr-black"}>
                    <span className={"text-base font-semibold"}>{t("Leads_Table_Removed")} </span>
                    <span className={"text-sm"}>({removedNum})</span>
                  </div>
                </TabItem>
              </TabsContainer>
              <div className={"flex grow items-center justify-end gap-1 pr-4 text-sm font-semibold text-cr-yellow"}>
                <InformationCircleIcon className={"size-6 shrink-0"} />
                <span>{t("Prospects_ImportModal_LeadsStep_Advanced_Searching_Tip")}</span>
              </div>
            </div>
            <div className={"flex grow"}>
              <Table<TSearchingStageColumn, TSearchingStageRow>
                grow
                ghost
                data={rows}
                className={"absolute rounded-t-none"}
                columnsMeta={columnsMeta}
                orderBy={orderBy}
                onOrder={setOrderBy}
              >
                {({data: rowData, pinnedColumn}) => {
                  if (data?.meta?.ongoing_search === false && rowData.length === 0) {
                    return <NoResults />
                  }

                  return rowData.map((row, index) => (
                    <div className={"group/row relative contents"} key={row.id}>
                      <TableRow row={row} rowIndex={index} pinnedColumn={pinnedColumn} />
                      {row.id === "loading" ? null : (
                        <div
                          className={"col-span-full hidden items-center justify-end pr-6 group-hover/row:flex"}
                          style={{gridRow: index + 2}}
                        >
                          <div className={"z-30"}>
                            {tab === "added" ? (
                              <Button
                                variant={"outlined"}
                                shape={"round"}
                                color={"red"}
                                size={"xs"}
                                iconLeft={<TrashIcon className={"size-5"} />}
                                onClick={() => addRemovedSearchId(row.id)}
                              >
                                {t("Prospects_ImportModal_LeadsStep_Advanced_Searching_RemoveButton")}
                              </Button>
                            ) : (
                              <Button
                                variant={"outlined"}
                                shape={"round"}
                                color={"blue"}
                                size={"xs"}
                                iconLeft={<PlusIcon className={"size-5"} />}
                                onClick={() => removeRemovedSearchId(row.id)}
                              >
                                {t("Prospects_ImportModal_LeadsStep_Advanced_Searching_UnremoveButton")}
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                }}
              </Table>
            </div>
          </div>
        </ProspectsFromLeadsFrame>
      </ProspectsFromLeadsLayout>
    </SearchingStageContext>
  )
}
