import React from "react"
import {useTranslation} from "react-i18next"

import apolloImage from "../../../../../assets/apollo.png"
import {TColumnMeta} from "../../../../../components/Table/utils/shared.ts"
import {TAllColumns, TCommonRow} from "../types.ts"
import {tableLoadingIndicator} from "./common.tsx"

export const useSourceColumn = <TColumns extends TAllColumns, TRow extends TCommonRow>(): TColumnMeta<
  TColumns,
  TRow
> => {
  const {t} = useTranslation()

  return React.useMemo(
    () => ({
      column: "source" as TColumns,
      headerCellClassName: "!bg-cr-blue-super-light",
      HeaderCellValue: () => t("Leads_EditingFlyout_Source"),
      CellValue: ({row}) => {
        if (row.id === "loading") {
          return tableLoadingIndicator
        }

        return <img src={apolloImage} className={"h-6 w-6"} alt={"Apollo"} title={"Apollo"} />
      },
    }),
    [t]
  )
}
